<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>

    <div class="content-container">
      <h1 class="text-bold">About the Fair</h1>
      <!-- <h2 class="text-bold">About Shenzhen International Furniture Exhibition & Shenzhen Creative Week</h2> -->
      <div class="section">
        <p class="p4">
          Shenzhen Creative Week is held annually in March, South China’s most important season for sourcing furniture and home interior products. The primary exhibition remains the Shenzhen International Furniture Exhibition (SIFEChina), a high-quality commercial design exhibition in China. Established in 1996, the fair is organized by the Shenzhen Furniture Association together with the retail conglomerates EasyHome, and Fusen Noble-House. 
        </p>
        <p class="p4">
          Adhering to the traditions of being “design oriented, trend-setting, and continuous innovation”, the fair utilizes design to connect and integrate with urban culture. Shenzhen Creative Week has increasingly become a window for the furniture and design industries to understand the city. It has also evolved into a strategic platform for connecting international design resources with Chinese manufacturing and facilitating Chinese manufacturing to access international and domestic markets. Through implementing high standards, Shenzhen Creative Week drives the high-quality development of China’s home furnishing industry.
        </p>
        <div class="video-container">
          <video id="my-video" controls class="fill">
            <source src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/SZCW23_en.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      <!-- <h2 class="text-bold">SIFE & SZCW 2025</h2> -->
      <div class="section">
        <p class="p4">
          Covering an exhibition area of 200,000m<sup>2</sup>, Shenzhen Creative Week + SIFEChina features 5 major exhibitions presenting comprehensive smart urban living solutions.
        </p>
        <ul class="theme-intro-box">
          <li>
            <div>Halls 9,10,11,13,14,15</div>
            <div><b>Shenzhen International Furniture Exhibition</b></div>
            <div><i>Original Chinese furniture from China’s top 250 brands</i></div>
          </li>
          <li>
            <div>Hall 12</div>
            <div><b>Design Dome</b></div>
            <div><i>A special curation connecting international brands/designers & Chinese design.</i></div>
          </li>
          <li>
            <div>Halls 1,3,5,7</div>
            <div><b>Smart+ | Smart Home</b></div>
            <div><i>Smart living ecosystems | Command & Control, Kitchen & Bath, Sleep systems & Lighting.</i></div>
          </li>
          <li>
            <div>Hall 8</div>
            <div><b>Lifestyle & Décor</b></div>
            <div><i>Contemporary young consumer lifestyle, art, and culture</i></div>
          </li>
          <li>
            <div>Halls 4,6</div>
            <div><b>Integrated Supply Chain</b></div>
            <div><i>Home décor suppliers, Online distributors</i></div>
          </li>
        </ul>
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen Creative Week: About the World Furniture Fair', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week'},
      { name: 'description', content: 'SZ Creative Week is Held Annually for Sourcing Furniture & Home Interior Products. This Design Exhibition in China is Organized By Shenzhen Furniture Association.'},
    ],
  },
  data() {
    return {
      current: 'intro',
    }
  },
  created() {

  },
  destroyed () {
    let videoElement = document.querySelector('#my-video');
    if (videoElement) {
      // 销毁 VIDEO 元素
      videoElement.pause(); // 停止播放
      videoElement.removeAttribute('src'); // 清空srcs属性来停止加载
      videoElement.load(); // 调用 load() 方法，以确保所有相关数据都被卸载。
      // 将 VIDEO 元素的事件监听器设为 null，以避免内存泄漏。
      videoElement.oncanplaythrough = null;
      videoElement.onerror = null;
      videoElement.remove();
      videoElement = null; // 最后将 VIDEO 元素赋值为 null，以释放其占用的内存。
    }
  },
  methods: {

  },
}
</script>

<style scoped lang="less">

// 移动端
@media screen and (max-width: 1023px) {
  .content-container {
    width: 100%;
    padding: 20px;
    
    h1 {
      font-size: 18px;
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 15px;
      text-align: center;
      margin: 16px 0;
    }
    
    .video-container {
      width: 100%;
      height: 190px;
      background-color: #fff;
      border-radius: 3px;
      overflow: hidden;
      margin: 20px 0;
    }
    
    .section {
      margin: 10px 0;
    }
    .p4 {
      font-size: 13px;
      line-height: 2em;
      // text-align: justify;
      margin: 0 0 4px;
    }
    
    .theme-intro-box {
      font-size: 14px;
      line-height: 2em;
      
      li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 8px;
        margin-bottom: 8px;
      }
    }
  }
}

// pc
@media screen and (min-width: 1024px) {
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 190px;
    margin: 15px auto 20px;
    
    h1 {
      font-size: 6px;
      text-align: center;
    }
    h2 {
      width: 155px;
      margin: 0 auto;
      font-size: 6px;
      text-align: center;
    }
    
    .video-container {
      width: 100%;
      height: 107px;
      background-color: #fff;
      border-radius: 2px;
      overflow: hidden;
      margin: 5px 0;
    }
    
    .section {
      margin: 10px 0;
    }
    
    .p4 {
      font-size: 3.6px;
      line-height: 1.8em;
      text-align: justify;
      margin: 0 0 4px;
    }
    
    .theme-intro-box {
      font-size: 3.6px;
      line-height: 2em;
      
      li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 8px;
        margin-bottom: 2px;
      }
    }

  }
}

</style>